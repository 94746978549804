import { render, staticRenderFns } from "./CustomReactionsStep.vue?vue&type=template&id=4b6b9faf&scoped=true"
import script from "./CustomReactionsStep.vue?vue&type=script&lang=ts"
export * from "./CustomReactionsStep.vue?vue&type=script&lang=ts"
import style0 from "./CustomReactionsStep.vue?vue&type=style&index=0&id=4b6b9faf&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b6b9faf",
  null
  
)

export default component.exports