
import { PropType, computed, defineComponent, watch, ref, onBeforeMount, onMounted } from 'vue'
import * as _ from 'lodash'
import useRouter from '../../../../../hooks/useRouter'
import { useProject } from '../../../../../hooks/useProject'
import { CONNECTOR_TYPES } from '../../../../../vars/general'
import api from '../../../../../api/api'
import CustomActivatorSelect from '../../../../inputs/CustomActivatorSelect.vue'
import BaseInputOutlined from '../../../../inputs/BaseInputOutlined.vue'
import TextInputGroup from '../../../../ui/TextInputGroup.vue'
import AdvancedMessageEditor from '../../../../ui/AdvancedMessageEditor.vue'
export default defineComponent({
    props: {
        value: {
            type: Object,
            required: true,
        },
        app: {
            type: Object,
            required: true,
        },
        triggerIdFromQuery: {
            type: String,
            required: false,
        },
        answersList: {
            type: Object,
            required: false,
        },
    },
    components: { TextInputGroup, CustomActivatorSelect, BaseInputOutlined, AdvancedMessageEditor },
    setup(props, { emit }) {
        const { route } = useRouter()
        const trigger = computed(() => route.value.query.id)
        const activeTab = ref(0)

        const tabs = [
            {
                tabName: 'Подтверждение',
                key: '1',
                reactionSwitchName: 'При подтверждении/отмене визита только со стороны гостя'
            },
            {
                tabName: 'Отмена',
                key: '2',
                reactionSwitchName: 'При подтверждении/отмене визита только со стороны гостя'
            },
        ]
        const activeTabKey = computed(() => tabs[activeTab.value]?.key)
        const answersListNew = ref(props.answersList)
        const reactions = computed(() => {
            if(props?.triggerIdFromQuery && props?.triggerIdFromQuery?.startsWith('custom_')) {
                return answersListNew?.value?.[activeTabKey.value] || []
            }
            return props.value.answersList?.[activeTabKey.value] || []
        })

        const answers = computed(() => {
            return props.value.answers?.[activeTabKey.value] || ''
        })
        const updateReactions = (val: string[]) => {
            const answersList = Object.assign({}, props.value.answersList, { [activeTabKey.value]: val })
            if(props?.triggerIdFromQuery && props?.triggerIdFromQuery?.startsWith('custom_')) {
                answersListNew.value = answersList
                props.value.answersList = answersListNew.value
            } else {
                emit('input', Object.assign({}, props.value, { answersList }))
            }            
        }
        const updateReAnswers = (val: string) => {
            if (val) {
                props.value.answers[activeTabKey.value] = val;
            } else {
                delete props.value.answers[activeTabKey.value];
            }            
        }

        onMounted(() => {
            if (!props.value.answers) {
                props.value.answers = {}
            }
        })

        return {
            trigger,
            tabs,
            activeTab,
            reactions,
            answers,
            updateReactions,
            updateReAnswers
        }
    },
})
